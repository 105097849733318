.scheduleCont {
  display: flex;
  align-items: flex-start;
  border: 1px solid #c7c6c5;
  border-left: none;
  border-right: none;
  line-height: 45px;
}

.schedulecheckbox {
  flex-basis: 5%;
  /* background: none; */
  & input {
    /* background-image: url('../../assets/icons/toggleOff.svg') ;
    background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px; */
    display: none;
  }
  & svg {
    fill: #fff;
  }
}

.formsbmitcnclbtn {
  display: flex;
  margin: 60px 0;
  & button {
    flex-basis: 25%;
    margin-right: 30px;
    border-radius: 20px;
  }
}

.backbtn {
  background: #f4f4f4;
  color: #111111;
  border: 1px solid #747474;
  &:hover,
  &:active,
  &:focus {
    background: #ebebeb;
  }
}

.sbmtbtn {
  &:disabled {
    background-color: #b1b1b1;
  }
}
