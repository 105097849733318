@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 10px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 30px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 7px;
  }
}
.dummyheight {
  @media (min-width: 767px) {
    min-height: 676px;
  }
}
.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
  @media (max-width: 767px) {
    padding-bottom: 40px;
    margin-bottom: 10px;
  }
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
.reqToBook {
  background-color: var(--marketplaceColorDark) !important;
}
.reqToBook:hover {
  background-color: var(--marketplaceColorDark) !important;
}
.reqToBook:disabled:hover {
  background-color: var(--matterColorNegative) !important;
}
.helpNo {
  /* display: none; */
  font-size: 15px;
  line-height: 25px;
  color: rgb(0, 0, 0);
  font-family: 'poppins' !important;
  

  font-weight: 600;
  @media (max-width: 575px) {
    display: inline-block;
  }
}
.deskno {
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-family: 'poppins' !important;
  & > a {
    text-decoration: underline !important;
  }
  @media (max-width: 575px) {
    display: none;
  }
}
.or {
  text-align: center;
  /* position: static; */
  position: relative;
  margin-top: 15px;
  /* z-index:1; */
  /* z-index: -1; */
  &::before {
    position: absolute;
    content: '';
    background-color: #292929;
    top: 16px;
    left: 30px;
    width: 35%;
    height: 1px;
    @media (max-width: 767px) {
      left: 0px;
      width: 45%;
    }
  }

  &::after {
    position: absolute;
    content: '';
    background-color: #292929;
    top: 16px;
    right: 30px;
    width: 35%;
    height: 1px;
    @media (max-width: 767px) {
      right: 0px;
      width: 45%;
    }
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
.callNum {
  font-family: 'poppins' !important;

  /* background-color: rgb(255,255,255); */
  font-size: 16px;
  text-align: center;
}
