@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  @media(max-width:767px){
    justify-content:unset;
    flex:0 0;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 0;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 40px;
  @media(max-width:767px){
    margin-top:60px;
  }
}

.bottomWrapper button {
  background: linear-gradient(116.91deg, #e0883d 9.39%, #fe5f50 91.58%);
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 44px;
  border-radius: 30px;
  color: #fff;
  letter-spacing: 0.8px;
  min-height: auto;
}
.bottomWrapper button:hover {
  background: linear-gradient(116.91deg, #fe5f50 9.39%, #e0883d 91.58%);
  text-decoration: none;
}
/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.passwordDiv {
  position: relative;
}                                   
.view {
  position: absolute;
  top: 50px;
  right: 10px;
  fill: transparent;

  &:hover {
    cursor: pointer;

    & .hide {
      display: block;
    }
  }
  @media(max-width:767px){
    top: 42px;
  }
}
.hide {
  position: absolute;
  top: 47px;
  right: 10px;
  fill: transparent;
  &:hover {
    cursor: pointer;

    & .hide {
      display: block;
    }
  }
  @media(max-width:767px){
    top: 40px;
  }
}
.passwordDiv {
  position: relative;
  margin-top: 20px;
}
