.VenueCardMain {
  height: 100%;
  width: 100%;
  background: #ffffff;
  /* margin: 35px; */
  /* border: 1px solid #f4f4f4; */
  /* box-shadow: 0px 0px 6px 2px #cecece;
  border-radius: 25px; */
  /* display: flex;
  flex-direction: column; */
  /* margin-right: 30px; */
}
.card {
  float: left;
  margin-right: 20px;
  height: 100%;
  width: 200px;
  box-shadow: 0px 0px 6px 2px #cecece;
  border-radius: 25px;
  @media(max-width:575px){
    width: 183px;
  }
}
.VenueCardTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #292929;
}
.linkText {
  color: var(--marketplaceColorDark);
  cursor: pointer;
  margin: 0;
  padding: 0 5px 0 0;
  font-weight: 500;
  &:hover {
    color: var(--marketplaceColorHoverDark);
    text-decoration: underline;
  }
}
.VenueCardImg {
  width: 169px;
  height: 113px;
  margin: 10px auto;
  border-radius: 18px;
}

.VenueCardInfo {
  text-align: center;
  line-height: 21px;
  min-height: 80px;
}

.label {
  width: 54px;
  height: 18px;
  left: 651px;
  top: 477px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #939291;
}

.value {
  margin-left: 10px;
  margin-right: 5px;
  width: 15px;
  height: 18px;
  left: 716px;
  top: 477px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #292929;
}

.separator {
  margin: 10px auto;
  width: 168px;
  height: 0px;
  border: 0.5px solid #f4f4f4b3;
}
.editBtnDiv {
  /* border-top: 1px solid #f4f4f4; */
  & button {
    border: none;
    margin: 0px 0 10px;

    & svg {
      fill: none;
      margin-right: 5px;
    }
    font-size: 12px;
    font-weight: 500;
  }
  display: flex;
  justify-content: center;
}
.vcimg {
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 4px 0px #cecece;
  border-radius: inherit;
}
.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #cecece;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  composes: h6 from global;
  color: var(--matterColor);

  margin-bottom: 0;
}
/* .duration{
  min-height: 61px;
} */