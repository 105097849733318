.root {
  width: auto;
  height: auto;
  position: relative;
  outline: none;
  margin: auto 15px;
}
.searchRoot {
  width: auto;
  height: auto;
  outline: none;
  margin: auto 15px;
}
