.selectWrapper {
  & button {
  }
  & button + div {
    border: 1px solid #f4f4f4;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 20px !important;
    @media(min-width:1200px){
      right:0;
    }
  }
}

.dropdownButton {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid #f4f4f4;
  border-radius: 40px;
  cursor: pointer;
  color: #292929;
  font-weight: 500;
  cursor: pointer;
  font-size:16px;
  &:hover,
  &:focus,
  &:active {
    border-color: #292929;
  }

  & .downArrow {
    fill: transparent;
    margin-left: auto;
    width:14px;
    height:8px;
    & path {
      stroke-width: 1px;
      stroke: #292929;
    }
  }
}
.aofftd {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  /* padding: 8px; */
  border-radius: 20px;
  & .option {
    background-color: white;
    border-bottom: 1px solid #edebe9;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    padding: 12px 8px;

    &:last-child {
      border-bottom-width: 0px;
      margin-bottom: 0px;
    }

    & .label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: #292929;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      & span {
        text-transform: capitalize;
      }
    }

    & .icon {
      margin-right: 8px;
      fill: transparent;
    }

    & input {
      display: none;
    }
  }

  & .selectedOption {
    & .icon {
      & > path:first-child {
        stroke: #fe5f50;
        fill: #fe5f50;
      }
    }
  }

  & .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    position: relative;
    margin-bottom: 12px;
    margin-top: 4px;
    margin: 12px 8px 8px;
    /* padding-bottom: 0px;
    padding-top: 12px; */

    & + div {
      box-shadow: none !important;
      & > div:first-child {
        padding: 0px 10px 8px;
        scrollbar-width: default;
        scrollbar-color: #dedede transparent;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #dedede;
          border: 4px solid #dedede;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          border-color: #c4c4c4;
          background-color: #c4c4c4;
        }
      }
    }

    & input {
      min-width: 216px;
      height: 36px;
      background: #ffffff;
      padding: 4px 16px;
      border-bottom-width: 0px;
      padding-right: 42px;
      transition: border-color 0.2s ease-in-out !important;
      font-size: 14px;
      line-height: 19px;

      border: 1px solid #292929;
      border-radius: 40px;
      font-weight: 400;

      /* &:hover,
      &:focus,
      &:active {
        border-color: #292929;
      } */
    }

    & .searchIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      fill: transparent;
      width: 16px;
      height: 16px;
    }
    &::after {
      content: '';
      position: absolute;
      height: 32px;
      width: 1px;
      background-color: #292929;
      top: 50%;
      transform: translateY(-50%);
      right: 36px;
    }
  }

  /* & > div:last-child {
      & > div:first-child {
        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 400;
          & input {
            width: 16px;
            height: 16px;
          }
        }
      }
    } */
}
.buttonLabel {
  white-space: nowrap;
}
