@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
  margin-left: 5px;
  font-size:14px !important;
  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
  @media(max-width:767px){
    margin-bottom:0;
  }
}
