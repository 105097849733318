.rootContainer {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.collapsable {
  height: auto;
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.collapsable > * {
  margin-top: 10px;
  @media (max-width: 767px) {
    margin-top: 25px;
  }
}

.collapsable > *:first-child {
  margin-top: 0;
}

.timeContainer,
.ParticipantsContainer,
.extraContainer,
.reservationContainer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.timeContainer:hover,
.ParticipantsContainer:hover,
.extraContainer:hover,
.reservationContainer:hover {
  /* background-color: #f5f5f5; */
}

.valueContainer {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-weight: bold;
  /* margin-bottom: 5px; */
  margin-bottom: 0;
}

.velue {
  font-size: 16px;
  color: #666;
}

.arrowContainer {
  display: flex;
  align-items: center;
}

/* options selected section starts from here */

/* container styles */
.options-container {
  position: relative;
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

/* selected option styles */
.selected-option {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.selected-option:before {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* options list styles */
.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
}

.option:hover {
  /* background-color: #ccc; */
  background-color: var(--marketplaceColorDark);
  /* color: #fff; */
}

.selected {
  background-color: #ccc;
}

.selectContainer {
  /* overflow-y: scroll; */
  position: absolute;
  background: #fff;
  border: #f5f5f5 1px solid;
  border-radius: 30px;
  box-shadow: #666;
  /* max-height: 250px; */
  width: 100%;
  z-index: 99;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1;
  @media (max-width: 767px) {
    width: 95%;
  }
}

.optionsList {
  max-height: 250px;
  overflow: auto;
}

.rangeRow,
.rangeDiv {
  display: flex;
  align-items: center;
}
.error {
  border: 1px solid #fc2c10 !important;
}
.rangeDiv {
  border: 1px solid #000000;
  background-color: #ffffff;
  padding: 9px 23px;
  border-radius: 40px;
  margin: 10px;
  & button {
    border: none;
  }
  & input {
    padding: 0;
    border: none;
    text-align: center;
  }
}

.msgContainer {
  text-align: center;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-left: 12px;
}

.rangeContainer {
  max-width: 200px;
  text-align: center;
}

.optionActive {
  background-color: #fe5f50;
  color: #fff;
}

.invalidInp {
  border-color: #fc2c10;
}

.arrowContainer {
  & svg {
    fill: none;
  }
}
.timeContainer,
.ParticipantsContainer,
.extraContainer {
  border-bottom: 1px solid #d1d5db;
}
.hideBorderBottom {
  border-bottom: none;
}
.AttendeeWrap {
  display: flex;
}
.option:hover .AttendeeWrap > div:nth-child(2) {
  color: #fff;
}
.bookedProfileImg {
  /* border: 1px solid; */
  /* height: 36px;
  width: 41px; */
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.bookedimgDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.imgwrapper {
  /* display: inline-grid; */
  display: flex;
  & img,
  & div {
    border: 2px solid #fff;
  }
}
.negmar {
  margin-left: -20px;
}

.showBtn {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--marketplaceColorDark);
  margin-left: 5px;
}
.avatar {
  height: 35px;
  width: 35px;
  margin: 0px 5px 0 9px;
}
.hidePayment {
  display: none;
}
.otherAttendees {
  font-weight: 600;
}
.disabledOpt {
  color: #cecece;
  cursor: not-allowed;
  background-color: #f1f1f1;
  &:hover {
    background-color: #ebaea8;
  }
}
