@import '../../styles/customMediaQueries.css';

.root {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  position: relative;

  & > div {
    border-radius: 20px;
    left: 0 !important;
  }
  &:hover {
    border: 1px solid var(--matterColorAnti);
  }
  /* margin: 0 10px auto; */
}
.sortBySvg {
  margin-right: 5px;
  stroke: #000000;
}
.isSelected {
  background-color: var(--marketplaceColorDark);
  border-radius: 20px;
  border: none;
  color: #ffffff !important;
  &:hover {
    /* border: 1px solid var(--matterColorAnti); */
    color: #000000 !important;
    & .sortBySvg {
      stroke: #000000;
    }
  }
  & .sortBySvg {
    margin-right: 5px;
    stroke: #ffffff;
  }
}
.icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;

  @media (--viewportMedium) {
    bottom: 2px;
  }
}

.menuLabel {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  /* width: auto; */
  width: 129px;

  height: auto;
  min-height: 0;
  /* border-radius: 4px; */

  white-space: nowrap;

  &:focus {
    outline: none;
    text-decoration: none;
    box-shadow: none;
    color: #000000;
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 0;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: 4px;
  z-index: 9 !important;
  @media (max-width:767px){
    left: 50% !important;
    transform:translate(-50%, 0) !important;
    top: 100%;
    width: 100% !important;
    max-width: 300px !important;
    border-radius: 20px;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 400;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);
  min-height: 40px;
  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;
  font-weight: 400;
  font-size: 16px;
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}
.selectedMenuItem {
  background-color: var(--marketplaceColorDark);
  color: #ffffff !important;
}
.clearMenuItem {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
