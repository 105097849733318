@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  @media(max-width:575px){
    justify-content:unset !important;
    height: auto !important;
  }
}

.email {
  margin-top: 20px;

}
.email label{
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 400;
}
.email input{
  border: 1px solid #000;
  padding: 10px 13px;
  border-radius: 10px;
}
.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 50px;
}

.bottomWrapper button{
  background: linear-gradient(116.91deg, #e0883d 9.39%, #fe5f50 91.58%);
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 44px;
  border-radius: 30px;
  color: #fff;
  letter-spacing: 0.8px;
  min-height: auto;
}
.bottomWrapper button:hover{
  background: linear-gradient(116.91deg, #fe5f50 9.39%, #e0883d 91.58%);
  text-decoration: none;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

/* Helper links */
.modalHelperLink {
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}
