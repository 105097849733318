.root {
  display: inline-block;
  & > div {
    & > div {
      /* right: 458px !important; */
      @media (max-width: 1200px) {
        /* right: 57px !important; */
      }
      /* @media (max-width: 1199px) {
        right: 0 !important;
      } */
      @media (max-width: 991px) {
        right: 0 !important;
        left: -130px !important;
        /* width: 200px !important; */
        width:330px !important;
      }
      @media (max-width: 767px) {
        /* left: 83px !important; */
        width: 60% !important;
        margin: 10px 0;
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
        left: -80px !important;
      }
      /* @media (max-width: 420px) {
        left: 40px !important;
      } */
      /* @media (max-width: 360px) {
        left: 13px !important;
      } */
      /* @media (max-width: 320px) {
        left: -20px !important;
      } */
      /* width: 350px !important; */
      & form {
        & > div {
          & > div {
            & > div {
              & > div {
                & > div {
                  width: auto !important;
                  & > div {
                    margin-left: 0 !important;
                    @media (max-width: 767px) {
                      /* margin-left: 17px !important; */
                    }
                    & > div {
                      width: 100% !important;
                      @media (max-width: 767px) {
                        width: 85% !important;
                        /* left: 35px !important; */
                      }
                      /* z-index:-1 !important; */

                      & > div:first-child {
                        left: 2px !important;
                      }
                      & > div:nth-child(2) {
                        right: 0px !important;
                        left: inherit !important;
                        @media (max-width: 767px) {
                          right: -34px !important;
                        }
                      }
                      & > ul {
                        width: 100% !important;
                        display: flex !important;
                        border-bottom: 0 !important;
                        margin-top: 20px !important;
                        & > li {
                          width: 170px !important;
                        }
                      }
                    }
                  }
                  & > div:nth-child(2) {
                    /* z-index:auto !important; */
                    & > div {
                      & > div {
                        & svg {
                          height: 12px;
                          width: 12px;
                          @media (max-width: 767px) {
                            margin-left: 16px;
                          }
                        }
                      }
                    }
                    & > div:nth-child(2) {
                      width: 100% !important;
                      height: 350px !important;
                      & > div {
                        width: 100% !important;
                        height: 90%;
                        & > div {
                          width: 100%;
                          display: block;
                        }
                        & > div:nth-child(2) {
                          & > div {
                            padding: 0 14px !important;
                            & table {
                              width: 100% !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 15px;
  }
}
