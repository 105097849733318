@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.venueNme {
  width: fit-content;
  /* &:hover {
    cursor: pointer;
    text-decoration: underline;
  } */
}

.imgDetailRow {
  display: flex;
  /* height: 79%; */
  /* min-height: 229px !important; */
  overflow-y: hidden;
  /* overflow-x: auto; */
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    min-height: auto;
  }
}
.threeToTwoWrapper {
  /* Layout */
  /* flex-basis: 281px; */
  height: 158px;

  /* flex-basis: 50%; */
  display: block;
  width: 281px;
  position: relative;
  transition: var(--transitionStyleButton);

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
  @media (max-width: 991px) {
    width: 100%;
    flex-basis: 145px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    flex-basis: 172px;
    margin-bottom: 10px;
  }
}
.fullwh {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  /* 3:2 Aspect Ratio */
  /* padding-bottom: 66.6667%; */

  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  width: 100%;
  border-radius: var(--borderRadius);
  max-height: 100%;
  height: 170px;
  /* min-height: 206px; */
  object-fit: cover;
}

.info {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
  width: auto;
  flex-grow: 1;
  min-height: 200px;
  margin-top: 3px;
  @media (max-width: 991px) {
    min-height: 300px;
  }
  @media (max-width: 767px) {
    flex-grow: 0;
  }
  @media (max-width: 767px) {
    flex-basis: 40%;
    min-height: auto;
  }
}
.favicon {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favBtn {
  width: 24px;
  height: 24px;
  /* fill: var(--marketplaceColorDark); */
  fill: none;
  /* stroke: var(--marketplaceColorDark); */
}
.unfavBtn {
  cursor: pointer;

  fill: var(--marketplaceColorDark) !important;
}
.price {
  /* Layout */
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  /* margin-right: 18px; */
  margin-top: auto;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  font-size: 16px;
  font-weight: 500;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0 !important;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);
  font-size: 15px;
  line-height: 17px;
  font-weight: 700;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    justify-content: inherit;
  }
}
.ratingSec {
  margin-left: auto;
  @media (max-width: 991px) {
    margin-top: 10px;
    margin-left: 0;
  }
  @media (max-width: 575px) {
    margin-bottom: 5px;
    margin-top: 0;
  }
  & svg {
    fill: var(--marketplaceColorLight) !important;
    margin-left: 5px;
  }
}
.tagscont {
  /* display: block; */
  max-width: 100%;
  color: var(--marketplaceColorDark);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.srttxt {
  margin-top: 3px;
}
.srttxt p {
  color: #292929;
  font-size: 14px;
  font-weight: 400;
  /* margin: 10px 0; */
  margin: 5px 0 0;
  line-height: 22px;
  min-height: 50px;
  @media (max-width: 991px) {
    min-height: 30px;
  }
  @media (max-width: 767px) {
    min-height: auto;
  }
}
.srttxt p span {
  display: block;
  color: #7b7b7b;
}
.srttxt p span:first-child {
  font-weight: 500;
  color: #000;
}
.listDiv {
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & span {
    font-size: 14px;
  }
  & > span:nth-child(4) {
    /* display: none; */
  }
  @media (max-width: 1199px) {
    min-height: 40px;
  }
}
.listsText {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.circleDiv {
  display: block;
  width: 5px !important;
  height: 5px !important;
  margin: 0 5px 0 5px;
  background-color: #939291;
  border-radius: 50%;
}
/* .hdivider{
  margin: 10px 0;
  bor
} */
.bookingDiv {
  border-top: 1px solid #f4f4f4;
  /* margin-top: 15px; */
  padding-top: 8px;
  padding-bottom: 5px;
  /* height: 19%; */
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  /* justify-content: center; */
  gap: 7px;
  margin-top: auto;
  margin-bottom: 20px;
  & p {
    border: 1px solid #969494;
    min-width: 145px;
    min-height: 40px;
    border-radius: 20px;
    text-align: center;
    padding: 12px 10px;
    margin: 0 auto !important;
  }
  &::-webkit-scrollbar {
    @media (max-width: 767px) {
      height: 5px !important;
    }
  }
  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 7px;
    margin-top: 10px;
  }
}
.bookingBtn {
  min-width: 145px;
  /* min-height: 49px; */
  min-height: 40px;
  /* background: linear-gradient(116.91deg, #fe8456 9.39%, #fe5f50 91.58%); */
  background-color: var(--marketplaceColorDark);

  border: none;
  border-radius: 20px;
  color: #fff;
  /* padding: 3px 18px; */
  padding: 10px 12px;
  margin: 0px 0 0 0;
  /* line-height: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    /* background: linear-gradient(116.91deg, #fe5f50 9.39%, #fe8456 91.58%); */

    box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.08);
    background-color: var(--marketplaceColorHover);
    @media (max-width: 575px) {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.08);
    }
  }

  & p,
  .listingbutton {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    line-height: 18px;
  }
  & span {
    font-weight: 400;
    font-size: 12px;
  }
  @media (max-width: 1199px) {
    min-width: 170px;
  }
  /* @media (max-width: 991px) {
    min-width: 190px;
  }
  @media (max-width: 767px) {
    min-width: 170px;
  } */
}
.disabledBookingBtn {
  background-color: #67403c !important;
  z-index: 0;
  color: #000 !important;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &::before {
    position: absolute;
    content: 'Fully booked';
    color: #fff;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  margin-right: auto;
  padding-left: 5px;
  word-spacing: 30px;
  @media (max-width: 767px) {
    padding-left: 0;
    margin-right: 5px;
  }
}
/* .bookingDivNew{

} */

.titleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
