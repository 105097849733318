@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);

  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  width:100%;
  justify-content:center;
  @media(max-width:575px){
    /* justify-content: f; */
    column-gap: 10px;
  }
}

.filtersButton {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
  @media(max-width:575px){
    margin:0;
  }
}

.filtersButtonSelected {
  composes: button marketplaceTinyFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
  @media (max-width:767px){
    padding: 130px 0;
  }
  /* & > button{
    position:absolute !important;
  } */
  & > button:first-child{
    top: 59px;
    width: 100%;
    display: flex;
    background: #fff;
    margin-right: 7px;
    /* box-shadow:var(--boxShadow); */
     & > span{
      margin-left: auto;
     }
  }
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 180px;
  @media(max-width:767px){
    padding-bottom: 145px;
  }
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
