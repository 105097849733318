@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}

.lastUpdated {
  margin-top: 0;
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}

.unorderedList {
  list-style: disc !important;
  padding-left: 50px;
}

ul.unorderedList li {
  font-size: 15px;
}

a{
  word-break: break-all;
}

.headingStyle{
  font-weight: 600;
}

h4 {
  font-weight: 500;
}