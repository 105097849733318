.tabWrapperRoot {
  display: flex;
  flex-direction: column;
  border: 0px !important;
  padding: 0px;
  overflow-x: auto;
  /* min-width: 280px; */

  /* @media (max-width: 767px) {
    margin: 32px 12px 16px;
  } */

  /* @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0px 12px 0px;
  } */

  & .tabRoot {
    margin-left: 0px !important;
    margin-bottom: 8px;

    @media (max-width: 767px) {
      /* margin-bottom: 0px; */
    }

    & a {
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0px;
      color: #292929;
      background-image: unset !important;
      background-color: #fff;
      padding: 10px 16px;
      border-radius: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-width: 0px !important;

      & > span:last-child {
        flex-grow: 1;
      }

      & svg {
        margin-right: -8px;
        pointer-events: none;
        fill: transparent;

        & path {
          fill: #292929;
        }
      }

      &:hover,
      &:focus,
      &:active {
        background-color: #fe5f50;
        color: #fff;

        & svg {
          & path {
            fill: #fff;
          }
        }
      }
      font-size:16px;
    }
    & .selectedSideTab {
      background-color: #fe5f50;
      color: #fff;

      & svg {
        & path {
          fill: #fff;
        }
      }
    }
  }
}

.mobileBtnActive {
  border-bottom: 1px solid #a4a3a9;
  margin-bottom: 24px;
  padding-bottom: 16px;
}

.navSectionRoot {
  width: calc(100% - 24px);

  margin: 16px 12px;
  @media (min-width: 768px) {
    /* max-width: 328px; */
    max-width: 263px;
  }
}
