@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }

  @media (max-width: 575px) {
    padding: 0 5px;
    font-size: 18px;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.backnwschild {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background-color:#003D29;
  ;
  /* background-image:url(../../assets/btnbg.jpg); */
  /* background-repeat:no-repeat;
  background-size:cover; */
  height:320px;
  width:100%;
  gap: 95px;
  padding:20px 25px 25px;
  @media(max-width:1199px){
    height: 270px;
    padding: 30px 10px;
  }
  @media(max-width:991px){
    gap:50px;
    height: 295px;
  }
  @media(max-width:767px){
    flex-direction:column;
    height:auto;
  }
  /* &::before{
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6)
  } */
}
/* .backnwschild::before {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index:1;
} */
.backnewsec{
  display: flex;
  justify-content: center;
  gap: 50px;
  @media(max-width:991px){
    gap: 15px;
  }
  @media(max-width:767px){
    flex-direction:column;
  }
}
.backnewsec img {
  width: 100%;
  min-height: 500px;
  border-radius: 20px;
  @media (max-width: 575px) {
    min-height: 300px;
  }
}
.middlecont {
  position: absolute;
  padding: 0 30px;
  text-align: center;
}
.btngbl {
  /* background: linear-gradient(116.91deg, #e0883d 9.39%, #fe5f50 91.58%); */
  /* background-color: var(--marketplaceColorDark); */
  background-color:#fff;
  padding: 10px 15px;
  min-width: 200px;
  display: inline-block;
  border-radius: 10px;
  color: #000;
  letter-spacing: 0.8px;
  border: none;
  font-weight:500;
  @media(max-width:1199px){
    font-size:14px;
  }
}
.btngbl:hover {
  /* background: linear-gradient(116.91deg, #fe5f50 9.39%, #e0883d 91.58%);
  text-decoration: none; */
  box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.08);
  /* background-color: var(--marketplaceColorHover); */
  background-color:#fff;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  & button {
    margin-bottom: 20px;
  }
}
.backnwschild{
  position:relative;
  z-index:2;
  flex-direction: column;
  gap: 0;
}
.backnwscontent{
  color: #fff;
  text-align: left;
  position: relative;
  z-index: 2;
  /* & > p{
    font-size: 20px;
    font-weight:400;
    line-height: 30px;
    margin-bottom: 0;
    text-align: left;
    max-width: none;
    @media(max-width:1199px){
      font-size: 16px;
      line-height: 24px;
    }
    @media(max-width:767px){
      max-width: none;
    }
    @media(max-width:575px){
      font-size: 16px;
      line-height: 24px;
     }
    } */
  & .heading{
    font-size: 28px;
    line-height: 40px;
    font-weight: var(--fontWeightSemiBold);
    @media(max-width:1199px){
     font-size: 20px;
     line-height:30px;
    }
   }
/* 
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;  */
       
   @media(max-width:991px){
    font-size: 25px;
   }
   @media(max-width:767px){
    font-size: 20px;
    line-height:30px;
   }

}
.backnewsbtn{
 text-align: center;
 position:relative;
 z-index:2;
 margin-top:auto;
 @media(max-width:767px){
  margin-top:25px;
 }
}
.backnwschildNew{
  background-color:#750346;
}
.backnwstxt{
  & p{
    font-size:18px;
    font-weight:400;
    line-height: 30px;
    margin-bottom: 0;
    text-align: left;
    max-width: none;
    @media(max-width:1199px){
      font-size: 16px;
      line-height: 24px;
    }
     
    @media(max-width:991px){
      font-size: 15px;
      line-height: 24px;
     }
  }
}