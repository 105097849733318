.card-lnd {
  /* position: absolute;
  width: 651px;
  height: 548px;
  left: 112px;
  top: 153px;

  background: #ffffff;
  border-radius: 20px; */
  max-width: 50% !important;
}
.panelabc {
  background: red;
}
