@import '../../styles/customMediaQueries.css';

.root {
  /* display: flex; */
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 30px 24px;

  @media (--viewportMedium) {
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 20px 30px 20px;
  }
  @media (max-width: 1199px) {
    padding: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    column-gap: 30px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
  & > div {
    & > div {
      & > div {
        height: 150px !important;
        @media(max-width:991px){
          margin-bottom: 10px;
        }
        @media(max-width:575px){
          margin-bottom:3px;
        }
        & > div:nth-child(2) {
          & > div {
            & > :nth-child(3) {
              @media (max-width: 767px) {
                min-height: auto;
              }
            }
          }
        }
      }
    }
  }
}
.noSearchResults {
  color: var(--marketplaceColorNoResult);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.listingCard {
  cursor: pointer;
  margin-bottom: 36px;
  flex-basis: 100%;
  margin: 10px 0;
  /* box-shadow: 0px 1px 10px 3px #b2b2b2; */
  /* border: 1px solid black; */
  padding: 15px 15px 12px;
  border-radius: 20px;
  max-width: 50vw;
  height: 100%;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.06);
  /* max-height: 300px; */
  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    /* flex-basis: calc(50% - 12px); */
    /* margin-right: 24px; */
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    /* margin-right: 24px; */
  }
  @media (max-width: 991px) {
    margin: 20px 15px 0 15px;
    max-width: 46vw;
    /* flex: 0 0 46%;
    width: 46%; */
    padding: 15px 15px 10px;
  }
  @media (max-width: 767px) {
    flex: 0 0 46%;
    width: 46%;
    margin: 20px 0;
    min-height: 600px;
    max-width: 46vw;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 380px;
    margin: 20px auto;
  }
  @media (max-width: 420px) {
    max-width: 300px;
    margin: 20px auto 0;
    min-height: 400px;
  }
  /* @media (max-width: 767px) {
    flex: 0 0 50%;
    width: 50%;
    margin: 20px 10px 0 10px;
    min-height: 600px;
  } */
  /* @media (max-width: 360px) {
    max-width: 270px;
    margin: 20px auto 0;
    min-height: 400px;
  } */
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    /* margin-right: 0; */
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    /* margin-right: 0; */
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px 20px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.nft {
  padding: 0 36px 30px;
  @media (max-width: 575px) {
    text-align: center;
  }
}
.clickHere {
  color: #fe5f50 !important;
  font-size: 18px !important;
  margin-left: 2px;
  text-align: center;
}
