.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;
  border-radius: 25px !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 0%);
  border: 1px solid #c7c6c5;
  padding: 5px 20px;
  font-size: 16px;
  background: url('../../assets/icons/ArrowDropdown.svg') no-repeat;
  background-position: right 1rem center;
  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
    /* color: var(--matterColorDark) !important; */
  }

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    border: 1px solid var(--matterColorNegative);
    /* color: var(--matterColorNegative); */
    cursor: default;
    color: var(--matterColorDark) !important;
  }
}

.selectSuccess {
  color: var(--matterColor);
  /* border: 1px solid var(--successColor); */
  border: 1px solid var(--matterColorDark);

  &:hover,
  &:focus {
    border: 1px solid var(--matterColorAnti);
  }
}

.selectError {
  /* border-bottom-color: var(--failColor); */
  border: 1px solid var(--failColor);
  &:hover,
  &:focus {
    border: 1px solid var(--failColor);
  }
}
