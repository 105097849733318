.add {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

.dash {
  text-decoration: none !important;
}

.dash:hover {
  text-decoration: none !important;
}
