.root {
  /* position: relative; */
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  /* width: auto; */
  height: auto;
  min-height: 0;
  /* border-radius: 4px; */
  background-color: transparent !important;
  border-radius: 20px;

  /* &:active,
  &:focus {
    outline: none;
    border-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: none;
    background-color: var(--marketplaceColorButtonFocus) !important;
  } */
}
.labelMenuOpen {
  border-color: var(--marketplaceColorDark) !important;

  background-color: var(--marketplaceColorButtonFocus) !important;
  &:hover {
    border-color: var(--marketplaceColorDark) !important;
  }
}
.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--marketplaceColorButtonFocus) !important;
  /* border-color: var(--marketplaceColorDark) !important; */
  box-shadow: none !important;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  border: 1px solid var(--marketplaceColorDark);

  /* width: auto; */
  height: auto;
  min-height: 0;
  border-radius: 20px;
  /* border: 1px solid var(--marketplaceColor); */

  /* &:active,
  &:hover,
  &:focus {
    background-color: var(--marketplaceColorButtonFocus) !important;
    border-color: var(--marketplaceColorDark);

  } */
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate(0%, 0%);
  /* Position */
  position: absolute;
  /* z-index: var(--zIndexPopup); */
  z-index: 9;

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  /* var(--matterColorLight); */

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  border-radius: 20px;
  left: auto !important;
  /* left: -100px !important;
  right: -100px; */
}
.Filterpopwrap {
  & > div {
    & > div {
      /* right: 51% !important; */
    }
  }
}
