@import '../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 15px 24px 15px 24px;
  height: 100%;
  margin: 0 auto 0 0;
  @media (--viewportLarge) {
    padding: 15px 36px 15px 36px;
  }
}
.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
  text-decoration: none;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media(max-width:991px){
    padding: 0;
  }
}

.topbarDesktopLabel {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 8px 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  border: 1px solid rgb(216, 216, 216);
  padding: 5px 5px 5px 10px;
  height: 42px;
  /* width: 85px; */
  /* margin: auto 0; */
  border-radius: 21px;
  &:hover {
    /* box-shadow: 0px 0px 6px var(--marketplaceColorDark); */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

    border-radius: 30px;
  }

  /* &:active {
    border-bottom: 0;
  } */
}
.profileMenuIsOpen {
  padding: 5px 5px 5px 10px;

  &:hover {
    /* border-bottom: 0; */
  }
}
.profileMenuLabel svg {
  height: 22px;
  width: 22px;
  /* margin: 0 5px; */
  stroke: #000;
  fill: #000;
}
.profileMenuLabel svg.coloricon {
  height: 35px;
  width: 35px;
  margin: 0 0 0 8px;
  stroke: var(--marketplaceColorDark);
  fill: var(--marketplaceColorDark);
}

.avatar {
  height: 29px;
  width: 29px;
  margin: 0px 5px 0 9px;
}
.profileMenuDiv {
  position: relative;
}
.profileMenuContent {
  width: 220px !important;
  padding: 7px 0;
  border-radius: 15px;
  overflow-x: hidden;
  right: 0 !important;
  left: auto !important;
  & > div {
    display: none;
  }
}
.loginMenuContent {
  width: 220px !important;
  padding: 7px 0;
  border-radius: 20px;
  overflow-x: hidden;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorLight);
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink,
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  flex-shrink: 0;
  height: 100%;
  padding: 15px 10px;
  color: var(--matterColor);
  width: 100%;
  display: block;

  &:hover {
    /* color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;*/
    /* background: var(--marketplaceColorDark); */
    text-decoration: none;
    background-color: #f7f7f7;
    color: var(--matterColor) !important;
    /* color: #fff; */
  }
}

.signup,
.login {
  composes: h5 from global;
  padding-left: 10px !important;

  display: block;
  padding: 0;
  text-decoration: inherit;
  margin: 0;

  /* @media (--viewportMedium) {
    margin: 28px 0;
  } */
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;
  position: relative;
  display: block;
  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 10px 20px !important;
  line-height: 24px;
  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  /* border-radius: 20px; */
  &:hover {
    /* color: var(--matterColorLight); */
    text-decoration: none;
    /* background-color: var(--marketplaceColorDark); */
    background-color: #f7f7f7;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top: 20px; */
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.dashboardLink {
  margin-right: 10px;
}

.profileMenuContent, .loginMenuContent{
  right:0;
  left:unset !important;
}