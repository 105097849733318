.scheduleCont {
  display: flex;
  align-items: flex-start;
  border: 1px solid #c7c6c5;
  border-left: none;
  border-right: none;
  line-height: 45px;
}

.schedulecheckbox {
  flex-basis: 5%;
  /* background: none; */
  & input {
    /* background-image: url('../../assets/icons/toggleOff.svg') ;
    background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px; */
    display: none;
  }
  & svg {
    fill: #fff;
  }
}

label {
  margin: 15px 0 10px;
  font-size: 18px;
  color: #959595;
  font-weight: 500;
  @media (max-width: 767px) {
    margin: 0px 0 10px;
    font-size: 15px;
  }
}

input {
  font-size: 18px;
}

.ownercont {
  display: flex;
  flex-direction: column;
}

.ownercontactdetailcont,
.ownerpaymentdetailcont {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.ownercontact,
.ownerpayment {
  flex-basis: 49%;
}

.formsbmitcnclbtn {
  display: flex;
  margin: 60px 0;
  & button {
    flex-basis: 25%;
    margin-right: 30px;
    border-radius: 20px;
    @media (max-width: 575px) {
      flex-basis: 100%;
      margin-right: 10px;
    }
  }
  @media (max-width: 767px) {
    margin: 20px 0 0;
  }
}

.aminitiesCont {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.aminitiesfieldcheck {
  flex-basis: 50%;
  & label {
    align-items: flex-start;
  }
  & svg {
    width: 22px;
    height: 22px;
    margin-right: 15px;
  }
}

.checkedbox {
  margin: 10px;
}

.cnclbtn {
  display: flex;
  align-items: center;
  color: #939291;
  &:hover,
  &:active,
  &:focus {
    color: #4a4a4a;
    cursor: pointer;
  }
}

.backbtn {
  background: #f4f4f4;
  color: #111111;
  border: 1px solid #747474;
  &:hover,
  &:active,
  &:focus {
    background: #ebebeb;
  }
}

.sbmtbtn {
  &:disabled {
    background-color: #b1b1b1;
  }
}

.buttonAddNew {
  border: none;
  padding: 20px;
  color: var(--marketplaceColorDark);
  @media (max-width: 767px) {
    padding: 15px 0;
  }
}

.chooseSelectCategory {
  margin-top: 30px;
}

.sectionhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  & hr {
    margin-left: 10px;
    width: 100%;
    border-color: #a4a3a933;
  }

  & span {
    /* max-width: 20%; */
    /* width: -webkit-fill-available; */
    font-size: 14px;
    color: #b3b3b3;
    flex: none;
  }
  /* & h2 {
    position: relative;
    width: 100%;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    &::after {
      height: 1px !important;
      right: 4px;
      left: unset !important;
      width: calc(100% - 200px);
      content: ' ';
      height: 1px;
      background: #a4a3a9;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
    }
  } */
}

.formCardCont,
.formCardContBorderless {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.formCardCont {
  padding: 25px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.09019607843137255) !important; */
}

.formCardFieldsQuarter {
  flex-basis: 32%;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.formCardFieldsFull {
  flex-basis: 100%;
}

.formCardFieldsHalf {
  flex-basis: 49%;
}

.crossbtnCont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -20px;
  margin-bottom: -20px;
}

.crossIcon {
  height: 22px;
  width: 22px;
  fill: transparent;
  & path {
    stroke: #8d8d8d;
  }
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.addImageWrapper {
  float: left;
  position: relative;
  /* width: 100%; */
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    /* width: calc(50% - 12px);
      margin: 0 0 24px 0; */
  }
  @media (max-width: 767px) {
    margin: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  /* padding-bottom: calc(100% * (2 / 3)); */
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.adImg {
  min-width: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 165px;
  background: #f8f8f8;
  border-radius: 25px;
  border: 1px dashed black;
  margin: 15px 15px 0 0;
  flex-direction: column;
  & svg {
    fill: #fff;
    width: 25px;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    width: 308px;
    min-width: auto;
  }
}

.addtxt {
  color: #fe8456;
  margin-top: 2px;
}

.thumbnail {
  min-width: 250px;
  width: 250px;
  margin: 36px 10px 0 0;
  border-radius: 25px;
  @media (max-width: 767px) {
    min-width: auto;
    width: 308px;
  }
}
.categorySelect {
  margin: 44px 0 16px;
}
.remcat {
  display: block;
  margin: auto 15px auto auto;
  border: 0;
  border-bottom: 1px solid #fe5f50;
  color: #fe5f50;
  font-size: 15px;
  line-height: 15px;
  padding: 0;
  & svg {
    fill: #fe5f50;
    width: 15px;
    height: 15px;
  }
  &:hover {
    border-bottom: 1px solid transparent;
  }
}
.offpeakdisabled {
  & input {
    border: 1px solid var(--matterColorNegative) !important;
    color: #7b7b7b !important;
  }
}
