@import '../../styles/customMediaQueries.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 1000;

  /* Dimensions */
  width: 100vw;
  padding: 5px 0;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--matterColor);
  color: var(--matterColorNegative);
  height: auto;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 0;
  /* width: 380px; */
  /* margin: 0 auto; */
  left: 50%;
  /* border-radius: 10px; */
  max-width: 800px;
  border-radius: 20px;
  flex-direction: column;
  padding: 30px 15px !important;
  margin: 0 15px;
  height:max-content;
  /* min-height: 200px; */
  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    padding: 8px 0;
  }
  @media(max-width:991px){
    max-width: 600px;
    min-height:200px;
  }
  @media(max-width:767px){
    max-width: 500px;
  }
  @media(max-width:575px){
    max-width: 380px;
    margin: 0 5px;
  }
  @media(max-width:400px){
    max-width: 320px;
  }
}

.message {
  composes: h5 from global;
  /* margin-left: 24px; */
  line-height: 25px !important;
  font-size: 14px !important;
  margin-bottom: 14px !important;
  text-align:center !important;
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 3px 12px 3px 12px;
  margin: 0 24px 0 6px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
}
