.root {
}

.input,
.textarea {
  /* border-bottom-color: var(--attentionColor); */
  border: 1px solid var(--matterColorNegative) !important;
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 16px;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 0%); */
  &:hover {
    border: 1px solid var(--matterColorAnti) !important;
  }

  &:active,
  &:focus {
    border: 1px solid var(--matterColor) !important;
  }
}

.inputSuccess {
  /* border: 1px solid var(--successColor); */
  border: 1px solid var(--matterColorDark) !important;

  /* &:hover {
    border: 1px solid var(--successColor);
  } */
}

.inputError {
  border: 1px solid var(--failColor);
  &:hover {
    border: 1px solid var(--failColor);
  }
}

.textarea {
  min-height: 64px;
  max-width: calc(100% - 40px);
  border: 1px solid var(--matterColorNegative) !important;
  font-weight: 400;
}
