@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  /* &:first-child {
    margin-left: 0;
  } */

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.horizontalSeperator {
  display: flex;
  flex-direction: row;
  color: #a4a3a9;
  font-size: 18px;
  line-height: 27px;
}

.hrLine {
  position: relative;
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.hrLine:after {
  content: ' ';
  height: 1px;
  width: 120px;
  background: #a4a3a9;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.hrLinewithOutLabel {
  position: relative;
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.hrLinewithOutLabel:after {
  content: ' ';
  height: 2px;
  /* width: 120px; */
  background: #a4a3a9;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.icon {
  margin-right: 20px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  & svg{
    height: 15px;
    width: 15px;
  }
}
.icon + * {
  overflow-x: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.link {
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* .desktopNav{

  @media (max-widt) {
    
  }
} */

.mobileHiddenNav {
  display: none !important;
}

.navSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  padding: 32px 16px;
  border: 1px solid #f4f4f4;
  /* #f4f4f4; */
  border-radius: 40px;
  min-width: 263px;
  cursor: pointer;
  @media(max-width:991px){
    min-width: auto;
  }
  /* @media(max-width:767px){
    min-width: 289px;
  } */
}

.mobileNavSection {
  padding: 8px 16px;
}

.mobilBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  padding: 0px 8px;

  & span {
    font-size: 18px;
    line-height: 32px;
    color: #292929;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    @media(max-width:767px){
      font-size: 16px;
    line-height: 26px;
    }
  }

  & button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px;
    margin: 0px;

    & svg {
      width: 24px;
      height: 24px;
      fill: transparent;
      transition: transform 0.3s ease-in-out;

      & path {
        stroke: #292929;
      }
      @media(max-width:767px){
        width: 15px;
        height: 15px;
      }
    }

    & .rotatedArrowDown {
      transform: rotate(-180deg);
    }
  }
}

.mobileSeperator {
  display: block;
  margin-top: -22px;
  width: 100%;
  height: 2px;
}

.desktopHiddentBtn {
  display: none !important;
}

.mobileNavActived {
  margin-top: -16px;
}
