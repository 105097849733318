.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  /* width: auto; */
  height: auto;
  min-height: 0;
  border-radius: 20px;
  border: 1px solid var(--matterColorNegative);

  &:hover,
  &:focus {
    outline: none;
    /* background-color: var(--matterColorLight); */
    border-color: var(--matterColorAnti);
    /* border: 1px solid var(--matterColorNegative); */

    /* background-color: transparent !important; */
    /* border-radius: 20px;
    text-decoration: none; */
    /* box-shadow: var(--boxShadowFilterButton); */
  }
}

.menuLabelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  /* width: auto; */
  height: auto;
  min-height: 0;
  border-radius: 20px;
  border: 1px solid var(--marketplaceColorDark);

  background-color: var(--marketplaceColorButtonFocus) !important;

  &:hover,
  &:focus {
    /* border: 1px solid var(--marketplaceColorDark); */
  }
}
.labelMenuOpen {
  border-color: var(--marketplaceColorDark) !important;

  background-color: var(--marketplaceColorButtonFocus) !important;
  &:hover {
    border-color: var(--marketplaceColorDark) !important;
  }
}
.menuContent {
  /* transform: translate(100%, 0%); */
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 20px;
  /* left: 0 !important; */
  left: auto !important;
  /* right:0 !important; */
  @media (max-width:991px){
   width:20% !important;
  }
  @media(max-width:820px){
    right:145px !important;
  }
}
.selectedMenuItem {
  background-color: var(--marketplaceColorDark);
  color: #ffffff !important;
}
/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);
  min-height: 40px;

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
  @media(max-width:991px){
    min-width:150px;
  }
}

.clearMenuItem {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
