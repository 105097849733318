.root {
  /* position: relative; */
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  /* width: auto; */
  height: auto;
  min-height: 0;
  background-color: transparent !important;
  border-radius: 20px;
  /* 
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  } */
}
.labelMenuOpen {
  border-color: var(--marketplaceColorDark) !important;

  background-color: var(--marketplaceColorButtonFocus) !important;
  &:hover {
    border-color: var(--marketplaceColorDark) !important;
  }
}
.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);
  border-radius: 20px;
  border: 1px solid var(--marketplaceColorDark);
  background-color: var(--marketplaceColorButtonFocus) !important;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  /* border: 1px solid var(--marketplaceColor); */

  &:hover,
  &:focus {
    /* border: 1px solid var(--marketplaceColorDark); */
  }
}
