.scheduledatacont {
  flex-basis: 95%;
}

.weekDay {
  display: flex;
  flex-direction: column;
}

.dayOfWeek {
  flex-basis: 20%;
  @media(max-width:767px){
    margin-left: 10px;
  }
}

.timenedit {
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
  flex-basis: 80%;
  flex-wrap: wrap;
  & > div:nth-child(2) {
    margin-left: 30px;
  }
  & button {
    color: #fe8456;
    border: none;
    & svg {
      width: 15px;
      fill: none;
      margin-right: 5px;
    }
    &:hover {
      color: var(--marketplaceColorHoverDark);
      text-decoration: underline;
    }
  }
}

.daybtncont {
  display: flex;
  justify-content: space-between;
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.removeschdulecont {
  display: flex;
  justify-content: space-between;
}

.scheduledonebtn {
  border-radius: 20px;
  padding: 0 15px;
  line-height: 8px;
  font-size: 14px;
  border: 1px solid #fe5f50;
  max-height: 30px;
  margin: 8px 0;
}
.peakTimeDiv {
  display: flex;
  align-items: center;
  border-top: 0.01rem solid #f4f4f4 !important;
  margin-top: 20px;
}
.peakRadioBtn {
  & label {
    margin: 0 20px 0 0;
    & > span:nth-child(2) {
      & svg {
        & circle {
          stroke: var(--marketplaceColorDark);
        }
      }
    }
    & > span:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
  }
}

.fieldSelect {
  border: 1px solid #939291;
  border-radius: 15px;
  padding: 0 10px;
  width: 100px;
  font-size: 13px;
  background-position: 80px center !important;
  background-size: 10px 10px;
}

.addanothercont {
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 15px;
    margin-right: 5px;
    height: 13px;
    & path {
      stroke: var(--marketplaceColorDark);
    }
  }
  & button{
    @media(max-width:767px){
      font-size:14px;
    }
  }
}
.labelText {
  font-size: 18px;
  font-weight: 400;
  color: #939291;
  @media(max-width:767px){
    font-size: 14px;
  }
}
.fieldWrapper {
  border: 1px solid #edebe9;
  margin-left: -50px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  @media(max-width:767px){
    margin-left: -20px;
    padding: 15px;
    margin-bottom:10px;
  }
}

.formRow {
  padding-bottom: 10px;
  border-bottom: 0.01rem solid #f4f4f4;
}
.linkText {
  color: var(--marketplaceColorDark);
  cursor: pointer;
  margin: 0;
  padding: 0 5px 0 0;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    color: var(--marketplaceColorHoverDark);
    text-decoration: underline;
  }
}
.fieldArrayRemove {
  font-size: 16px;
  font-weight: 500;
  color: #939291;
  @media(max-width:767px){
    font-size: 14px;
    line-height: 22px;
    margin-top: 15px;
  }

  & svg {
    margin-right: 10px;
  }
}

.timedisplay {
  background: #f4f4f4;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  margin: 10px 5px 10px;
}

.timechipcont {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 80%;
  justify-content: flex-end;
}

.scheduleinactive {
  opacity: 0.7;
  cursor: not-allowed;
}

.scheduleactive {
  opacity: 1;
}
