@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0.5s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* composes: defaultBackgroundImage from global; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  width: 100%;
  min-height: 100%;
  /* position: relative; */
  /* padding: 0 24px 0 24px; */

  /* @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  } */

  /* Special viewport for adjusting the heroContent's placement */

  /* @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  } */
}

.contentWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  padding: 16px 0;
  padding-bottom: 0px;
  /* max-height: 360px; */
  width: 90vw;
  max-width: 2000px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 575px) {
    flex-direction: column;
    padding: 16px 15px;
    max-width:100%;
    width:100vw;
  }

  & .leftContent {
    flex-basis: 50%;
    background: linear-gradient(to bottom, #fe8456 0%, #fe5f50 100%);
    color: #fff;
    padding: 28px 20px;
    min-width: 320px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    @media (min-width: 576px) and (max-width: 767.98px) {
      max-width: 240px;
      flex-basis: 100%;
      /* font-size:40px; */
      /* padding: 15px 20px; */
      padding: 40px 20px;
    }
    @media (max-width: 575px) {
      border-top-right-radius: 24px;
      border-bottom-left-radius: 0px;
      min-width: 100%;
      padding: 0px 20px;
    }

    & .title {
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 16px;
      margin-top:16px;
      @media (min-width: 1200px) {
        font-size: 40px;
      }
      /* @media (min-width: 992px) and (max-width: 1199.98px) {
        font-size: 30px !important;
      } */

      @media (min-width: 768px) and (max-width: 991.98px) {
        /* font-size: 20px; */
        /* line-height: 31px; */
        margin-top: 0;
      }
      @media (min-width: 576px) and (max-width: 767.98px) {
        margin-top: 5px;
        /* font-size: 20px;
        line-height: 25px; */
        font-size:37px;

        margin:0;
      }
      @media (min-width: 320px) and (max-width: 575.98px) {
        font-size: 28px;
        line-height:40px;
      }
    }
    & .subTitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  & .rightContent {
    flex-basis: 50%;
    width: calc(100% - 320px);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    position: relative;
    background-color: var(--marketplaceColorLight);
    & img {
      width: 100%;
      height: 362px;
      /* height: 100%; */
      object-fit: cover;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      background-color: -webkit-linear-gradient(to bottom, #fe8456 0%, #fe5f50 100%);

      @media (max-width: 575px) {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 24px;
        height:auto;
      }
    }

    @media (max-width: 575px) {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 24px;
      max-height: 378px;
      width: 100%;
    }
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}
.searchDiv {
  max-width: 800px;
  top: 180px;
  position: absolute;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-left: 16px;
  border-radius: 20px;
  padding: 20px;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 991.98px) {
    max-width: 700px;
    top: 50%;
    padding: 10px 15px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;
    /* max-width: 500px; */
    /* max-width: 84%; */
    /* top: 100px; */
    top: 50%;
    margin: 0 23px;
    padding: 12px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  }
  @media (max-width: 575px) {
    flex-direction: column;
    max-width: 84%;
    top: 50%;
    margin: 0 14px;
    padding: 12px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  }
 @media(max-width:375px){
  top: 45%;
 }
}
.filterButtonDiv {
  flex-basis:76%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height:22px;
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 991.98px) {
    flex-basis: 70%;
  }
}
.buttonRow {
  display: flex;
  justify-content: space-between;
}
.filtrBtn {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  color: #939291;
  border-bottom: 2px solid transparent;

  @media (min-width: 768px) and (max-width: 991.98px) {
    font-size: 16px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    font-size: 14px;
    white-space: nowrap;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px;
    white-space: nowrap;
  }

  & span {
    @media (min-width: 320px) and (max-width: 374.98px) {
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 80%;
    }
  }

  & svg {
    fill: none;

    & > path {
      stroke: #939291;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      height: 20px;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      height: 18px;
    }
    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 16px;
    }
  }
}
.selectedFilterBtn {
  color: #000000;
  border-bottom: 2px solid #000000;
  & svg {
    & > path {
      stroke: #000000;
    }
    & > g:nth-child(1) {
      & > path {
        fill: #000000;
      }
    }
    & > g:nth-child(2) {
      & > path {
        stroke: #000000;
      }
    }
  }
}
.filterInfoRow {
  line-height: 27px;
  margin-top: 10px;
  @media (min-width: 768px) and (max-width: 991.98px) {
    font-size: 16px;
  }

  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
    line-height: 21px;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
  }
}
.srchButtonDiv {
  flex-basis: 24%;
  text-align: center;
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-top: 5px;
  }
  /* @media (min-width: 320px) and (max-width: 575.98px) {
  } */
}
.srchButton {
  border: none;
  background-color: var(--marketplaceColorDark);
  color: #ffffff;
  padding: 10px 35px;
  border-radius: 30px;
  & svg {
    fill: none;
    margin-right: 5px;
  }
  &:hover {
    box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.08);
    background-color: var(--marketplaceColorHover);
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    padding: 8px 20px;
    & svg {
      height: 15px;
      display: inline-block;
    }
  }
}
.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
