.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media(max-width:420px){
    align-items: center;
    flex-direction: column;
    margin-top:0;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  margin: 0 20px 0 0;
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
  @media(max-width:991px){
    margin:0;
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  /* width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize); */
  width: 186px;
  height: 186px;
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  /* border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2); */
  cursor: pointer;
  border-radius: 50%;

  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop); */
    /* border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2); */
    border-radius: 50%;
  }
  @media(max-width:991px){
    width: 150px;
    height: 150px;
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 3px;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }

  & svg {
    fill: none;
    width: 36px;
    height: 40px;
    & path {
      stroke: #939291;
      stroke-width: 1px;
    }
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}
.uplaodTextDiv {
  display: flex;
  flex-direction: column;
  line-height: 31px;
  & > p:nth-child(1) {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--matterColorText);
    @media(max-width:991px){
    font-size: 14px;
    }

    & span {
      display: flex;
      align-items: center;
      & label {
        color: var(--marketplaceColorDark);
        cursor: pointer;
        text-decoration: underline;
        margin: 0;
        padding: 0 5px 0 0;
        font-size: 20px;
        font-weight: 600;
        &:hover {
          color: var(--marketplaceColorHoverDark);
        }
        @media(max-width:991px){
          font-size: 16px;
        }
      }
      @media(max-width:767px){
        align-items:baseline;
      }
    }
  }
  & > p:nth-child(2) {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    @media(max-width:991px){
      font-size:14px;
    }
  }
  @media(max-width:575px){
    width: 100%;
    align-items: center;
  }
}
.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }
  @media (max-width: 767px) {
    bottom: -50px;
    right: 0;
  }
  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  /* width: var(--ProfileSettingsForm_avatarSize); */
  /* height: var(--ProfileSettingsForm_avatarSize); */
  width: 186px;
  height: 186px;
  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop); */
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 27px;
  & > div {
    flex-basis: 49%;
    & label {
      font-size: 18px;
      font-weight: 400;
    }
    & input {
      transition: none;
      /* &:hover {
        border: 1px solid #000000;
      } */
      font-size:14px;
    }
  }
  @media(max-width:575px){
    flex-direction: column;
  }
}
.sectionContainer {
  /* margin-bottom: 59px; */
  margin-bottom: 27px;

  & > div {
    & label {
      margin-top: 0;
      font-size: 18px;
      font-weight: 400;
      @media(max-width:991px){
        width: auto;
        margin-right: 40px;
      }
      @media(max-width:575px){
        font-size:14px;
        margin-top: 10px;
      }
      @media(max-width:420px){
        margin-right: 0px;
       
      }
    }
    & textarea{
      @media(max-width:575px){
        font-size: 14px;
        padding:5px 15px;
      }
    }
  }
  @media(max-width:575px){
    margin-bottom:15px;
  }
}
.richEditorContainer {
  margin-bottom: 59px;

  & > label {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    @media(max-width:575px){
      font-size: 14px;
    }
  }
  & > div {
    & > div:nth-child(2) {
      & > div {
        & > div:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
          color: var(--matterColorAnti);
        }
      }
    }
  }
  @media(max-width:991px){
    margin-bottom: 40px;
  }
}
.submitButton,
.submitDisabledBtn {
  width: 30%;
  height: 50px;
  border: none;
  background-color: var(--marketplaceColorDark);
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  @media(max-width:1199px){
    width: 45%;
  }
  @media(max-width:991px){
    width: 55%;
    padding: 10px 15px;
  }
  @media(max-width:767px){
    width: 100%;
  }
}
.lastSection {
  display: flex;
  align-items: center;
  @media(max-width:420px){
    flex-direction:column;
  }
}
.submitButton {
  &:hover {
    box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.08);
    background-color: var(--marketplaceColorHover);
  }

}
.cancelButton {
  border: none;
  cursor: pointer;
  /* flex-basis: 30%; */
  font-size: 16px;
  font-weight: 400;
  margin-left: 50px;
  color: #939291;
  &:hover {
    color: #000000;
  }
  @media(max-width:575px){
    font-size: 16px;
    margin-top: 15px;
    margin-left:0;
  }
}
.borderAvatar{
  border-radius: 100px;
  max-width: 767px;
}