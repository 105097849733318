@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (--viewportLarge) {
    /* flex-direction: row; */
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    margin-bottom: 95px;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: flex;
  & > a {
    @media (max-width: 575px) {
      width: 70px !important;
      height: 70px !important;
    }
    & > span {
      @media (max-width: 575px) {
        font-size: 25px;
      }
    }
  }
}
.otherUser p button {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
}
.userInf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  & p {
    padding: 0;
    margin: 0;
  }
  & p:first-child {
    font-size: 19px;
    color: #767676;
    line-height: 28px;
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & p:last-child {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    & > span:nth-child(2) {
      text-decoration: underline;
      position: relative;
      margin-left: 30px;
      font-weight: 500;
      font-size: 21px;
      &::before {
        content: '';
        background-color: #000;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 12px;
      }
    }
    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    margin: 20px 24px 0 24px;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
  @media (max-width: 991px) {
    margin-top: 30px;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 123px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  composes: h5 from global;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 0;

  @media (--viewportMedium) {
    /* margin: 18px 24px 0 24px; */
  }
  @media (--viewportLarge) {
    /* margin: 24px 48px 47px 48px; */
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  composes: h5 from global;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}
.venadd {
  display: flex;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 22px;
  & div {
    text-decoration: underline;
    margin-left: 5px;
  }
  & div:hover {
    cursor: pointer;
  }

  & span {
    text-decoration: underline;
    margin-left: 5px;
  }
  & span:hover {
    cursor: pointer;
  }
  & b {
    text-decoration: none !important;
  }
}

.dash {
  text-decoration: none !important;
}

.dash:hover {
  text-decoration: none !important;
}

.refAdd {
  margin: 0px 0;
  display: inline-flex;
  font-size: 14px;
  line-height: 16px;
  @media (max-width: 575px) {
    line-height: 25px;
  }
}
.seconddiv {
  display: flex;
  background-color: #fafafa;
  padding: 35px;
  @media (max-width: 767px) {
    padding: 25px 15px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    padding: 25px 15px;
  }
}
.imgdiv {
  padding-left: 28.25%;
  /* min-width: 400px; */
  width: 40%;
  flex: 0 0 40%;
  position: relative;
  @media (max-width: 767px) {
    padding-left: unset;
    width: 50%;
    flex: 0 0 50%;
  }
  @media (max-width: 575px) {
    height: auto;
    height: -webkit-fit-content;
    width: 100%;
    flex: 0 0 100%;
    padding-top: 10px;
  }
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    object-fit: contain;
    object-position: top center;
    border-radius: 15px;
    @media (max-width: 575px) {
      position: inherit !important;
      height: auto !important;
    }
  }
}
.titlep {
  font-weight: 700;
  margin: 0 0;
  color: #000;
  @media (max-width: 575px) {
    font-size: 20px;
    margin: 10px 0;
  }
}
.listingdetdv {
  flex-grow: 1;
  padding-left: 55px;
  padding-top: 10px;
  padding-bottom: 20px;
  @media (max-width: 767px) {
    padding-left: 20px;
    width: 50%;
    flex: 0 0 50%;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    width: 100%;
    flex: 0 0 100%;
  }
}
.tydv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 10px;
  & > svg {
    width: 150px;
    height: 150px;
    fill: #fff;
    & path {
      stroke: #fe5f50;
    }
    @media (max-width: 575px) {
      width: 75px;
      height: 75px;
    }
  }
  @media (max-width: 575px) {
    margin: 30px 0 10px;
  }
}
.tymsgp {
  margin-top: 30px;
  max-width: 550px;
  text-align: center;
  @media (max-width: 575px) {
    padding: 20px 15px 10px;
    margin-top: 0;
  }
}
.pricediv {
  display: flex;
  justify-content: flex-start;

  & > p {
    flex-basis: 40%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #292929;
    margin: 0;
  }
}
.infos {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
  & svg {
    height: 22px;
    width: 22px;
    fill: #fff;
    margin-right: 10px;
  }
  & .info:last-child {
    &::after {
      display: none;
    }
  }
  @media (max-width: 991px) {
    row-gap: 10px;
  }
  @media (max-width: 575px) {
    row-gap: 5px;
  }
}
.info {
  white-space: nowrap;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.info::after {
  /* position: absolute; */
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  margin-left: 10px;
}
.gohomedv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 40px auto 10px;
  & svg {
    position: absolute;
    top: 5px;
    left: 2px;
    fill: #fff;
  }
  & > a {
    margin-left: 30px;
    color: #fe5f50;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}
.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #cecece;
  border-radius: 10px;
  width: 40%;
  @media (max-width: 575px) {
    width: 100%;
    height: 200px;
  }
  /* height: 100%; */
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  composes: h6 from global;
  color: var(--matterColor);

  margin-bottom: 0;
}
.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}
.bookinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 35px 0 35px 35px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 10px 15px;
  }
}
.review {
  display: flex;
  flex-direction: column-reverse;
  & > span {
    margin: 0;
  }
  & > p {
    margin: 0;
  }
}
.othrUserReview {
  align-items: flex-end;
}
.reviewsDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}
