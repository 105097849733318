@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);

  @media (max-width: 767px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 30px;
  }
  @media (max-width: 575px) {
    margin-top: 55px;
  }
}
.title span {
  color: var(--marketplaceColorDark);
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */

/* An image wrapper that defines the images aspect ratio */

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}
.filteredSearches {
  display: flex;
  width: 100%;
  @media (max-width: 1199px) {
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    padding: 0;
  }
  /* & > div {
    & > div {
      & > div {
        & > div {
          height: 460px;
        }
      }
    }
  } */
  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            & > div {
              & > div {
                & > div:nth-child(2) {
                  @media (max-width: 1199px) {
                    min-height: 180px !important;
                  }
                  @media (max-width: 360px) {
                    min-height: 195px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.searchLink {
  background: #ffffff;
  box-shadow: 1px 0px 8px 2px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  overflow: hidden;
  padding: 15px;
  max-width: 370px;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 375px) {
    max-width: 255px;
  }
}
.imagewrap {
  position: relative;
  margin: 0 0 15px;
}
.imagewrap img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
}
.favicon {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favicon svg {
  width: 24px;
  height: 24px;

  fill: var(--marketplaceColorDark);
}
.cardText {
  margin: 0 0 15px;
  line-height: 21px;
}
.cardText h2 {
  color: #000;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-bottom: 0px;
}
.cardText h2 span {
  margin: 0 0 0 auto;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.cardText h2 span svg {
  fill: #e0883d;
  margin: 0 0 0 5px;
}
.dotttl {
  font-size: 18px;
  font-weight: 700;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 16px;
  }
}
.srttxt p {
  color: #292929;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 22px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 14px;
  }
}
.srttxt p span {
  display: block;
  color: #7b7b7b;
}
.tagscont {
  display: block;
  max-width: 100%;
  color: var(--marketplaceColorDark);
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 14px;
  }
}

.pricetxt {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 14px;
  }
}
.pricetxt span {
  color: #7b7b7b;
  font-weight: 400;
}
.listDiv {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & span {
    font-size: 16px;
    @media (min-width: 320px) and (max-width: 575.98px) {
      font-size: 14px;
    }
  }
}
.listsText {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.circleDiv {
  display: block;
  width: 5px !important;
  height: 5px !important;
  margin: 0 5px 0 5px;
  background-color: #939291;
  border-radius: 50%;
}
.searchLink ul {
  display: flex;
  flex-wrap: wrap;
}
.searchLink ul li {
  color: #939291;
  font-size: 14px;
  font-weight: 400;
  /* padding: 0 5px; */
  &:nth-child(2, 3, 4) {
    padding: 0 20px;
    position: relative;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      width: 5px;
      height: 5px;
      background: #939291;
      position: absolute;
      border-radius: 30px;
      left: 5px;
    }
    &::before {
      content: '';
      width: 5px;
      height: 5px;
      background: #939291;
      position: absolute;
      border-radius: 30px;
      right: 5px;
    }
  }
}

.duration {
  color: #233039;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.searchLink hr {
  background: #edebe9;
  height: 1px;
  border: 0;
  margin: 15px 0;
}
.detailssec {
  display: flex;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.detailssecol {
  /* border: 1px solid var(--marketplaceColorDark); */
  background: linear-gradient(116.91deg, #e0883d 9.39%, #fe5f50 91.58%);
  color: #fff;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 0 8px;
  flex-basis: 50%;
  max-height: 68px;
  @media (max-width: 991px) {
    padding: 8px;
  }
  @media (max-width: 575px) {
    margin: 5px;
  }
  &:hover {
    background: linear-gradient(116.91deg, #fe5f50 9.39%, #e0883d 91.58%);
    text-decoration: none;
    cursor: pointer;
  }
}
.detailssecol p {
  /* color: #000; */
  margin: 0;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
}
.detailssecol p span {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.btngbl {
  /* background: linear-gradient(116.91deg, #e0883d 9.39%, #fe5f50 91.58%); */
  background-color: var(--marketplaceColorDark);
  border: none;

  padding: 10px 44px;
  display: table;
  border-radius: 30px;
  letter-spacing: 0.8px;
  margin: 35px auto 20px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.08);
    background-color: var(--marketplaceColorHover);
  }
  & a {
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 500;
  }
}

/* .btngbl:hover {
  background: linear-gradient(116.91deg, #fe5f50 9.39%, #e0883d 91.58%);
  text-decoration: none;
} */
.bookingDiv {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;

  /* scrollbar-width: default; */
  scrollbar-color: #e1e1e1 transparent;

  ::-webkit-scrollbar {
    border-radius: 10px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--matterColorAnti);
    /* border: 2px solid #fff; */
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--matterColorAnti);
    border-width: 0px;
    cursor: pointer;
  }
}
.bookingBtn {
  min-width: 158px;
  /* min-height: 49px; */
  max-height: 49px;
  width: 165px;
  /* background: linear-gradient(116.91deg, #fe8456 9.39%, #fe5f50 91.58%); */
  background-color: var(--marketplaceColorDark);

  border: none;
  border-radius: 20px;
  color: #fff;
  /* padding: 8px 18px; */
  margin: 5px 12px 0 0;
  /* &:hover {
    background: linear-gradient(116.91deg, #fe5f50 9.39%, #fe8456 91.58%);
    text-decoration: none;
    cursor: pointer;
  } */

  & p {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    line-height: 21px;
  }
  & span {
    font-weight: 400;
    font-size: 13px;
  }

  &:hover {
    box-shadow: 3px 3px 14px rgb(0 0 0 / 12%);
    background-color: var(--marketplaceColorHover);
  }
}
.listingCard {
  margin: 0 0 0 0;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  padding: 10px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  cursor: pointer;
  min-height: 465px;
  min-width: 320px !important;
  max-width: 370px;
  position: relative;
  /* height: 400px; */
  /* for class "imageDetailRow" */
  & > div {
    & > div {
      width: 100%;
    }
    & > div:nth-child(2) {
      min-height: 180px;
    }
  }

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column !important;

    /* for class ".ListingCard_threeToTwoWrapper" */
    & > div:nth-child(1) {
      /* width: 100%; */
      margin-bottom: 20px;
      @media (max-width: 767px) {
        flex-basis: 160px;
        height: 130px;
        margin-bottom: 15px;
      }
    }

    /* for class ".ListingCard_info" */
    & > div:nth-child(2) {
      margin-left: 0;

      /* for class ".ListingCard_mainInfo" */
      & > div:nth-child(1) {
        text-transform: capitalize;
        margin-bottom: 9px;
        /* for class "ListingCard_title" */
        & > div:nth-child(1) {
          font-size: 18px;
          font-weight: 700;
          /* text-transform: capitalize; */
          @media (max-width: 575px) {
            font-size: 16px;
          }
        }

        /* for class "ListingCard_srttxt" */
        & > div:nth-child(2) {
          & > span {
            font-size: 16px !important;
            font-weight: 600 !important ;
            @media (max-width: 575px) {
              font-size: 15px !important;
            }
          }

          & span {
            font-size: 16px !important;
            font-weight: 400;
            line-height: 22px;
            @media (max-width: 575px) {
              font-size: 15px !important;
            }
          }
        }

        & > div:nth-child(3) {
          & span {
            font-size: 16px !important;
            @media (max-width: 575px) {
              font-size: 15px !important;
            }
          }
        }
      }

      /* for class "ListingCard_price" */
      & > div:nth-child(2) {
        /* margin-top: auto; */
        position: absolute;
        bottom: 95px;
        /* @media(max-width:991px){
         margin-top:10px;
        } */
        @media (max-width: 991px) {
          bottom: 100px;
        }
        @media (max-width: 767px) {
          bottom: 120px;
        }
        @media (max-width: 380px) {
          bottom: 112px;
        }
        & > div {
          font-size: 18px;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        & > div:nth-child(2) {
          color: #7b7b7b;
          font-weight: 400;
        }
      }
    }
  }
  /* @media (min-width: 768px) {
    max-width: 23%;
    flex-basis: 33%;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
  @media (max-width: 767.98px) {
    flex-basis: 45%;
  } */
  @media (max-width: 1199px) {
    min-height: 490px !important;
  }
  @media (max-width: 991px) {
    min-width: 400px !important;
    min-height: 450px !important;
    max-width: 430px !important;
  }
  @media (max-width: 767px) {
    min-width: auto !important;
    width: 100% !important;
    margin: 0 auto;
    min-height: 484px !important;
  }
  @media (max-width: 575px) {
    /* min-height: 484px !important; */
    /* min-width:120px !important; */
    /* margin:0 -20px; */
  }
  /* @media(max-width:420px){
    margin: 0 10px;
  } */
}
.noCursor {
  cursor: pointer !important;
}
