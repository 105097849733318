.imageLabel {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.imgContain {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.adImg {
  min-width: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 165px;
  background: #f8f8f8;
  border-radius: 25px;
  border: 1px dashed black;
  margin: 15px 15px 0 0;
  flex-direction: column;
  & svg {
    fill: #fff;
    width: 25px;
  }
  &:hover {
    cursor: pointer;
  }
}

.upldbtnhide {
  display: none;
}

.imgPreview {
  width: 250px;
  border-radius: 25px;
  height: 165px;
  object-fit: cover;
}

.imgPrevCont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.imgPrev {
  display: flex;
  align-items: center;
  margin: 0 20px 20px 0;
}

.imgPreviewRmvBtn {
  margin: -10px 0 auto auto;
  display: flex;
  border: none;
  & svg {
    width: 24px;
    fill: #fe5f50;
    /* display: flex;
    align-items: flex-start;
    height: 165px; */
    margin-left: -20px;
    & path {
      stroke: #fff !important;
    }
  }
}

.addtxt {
  color: #fe8456;
  margin-top: 2px;
}
