@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  /* background-color: var(--matterColorBright); */
  background-color: var(--matterColorLight);

  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  flex-grow: 1;
  /* overflow: auto; */
  padding: 13px 10px 8px;
  display: flex;
  width: 100%;
  /* justify-content: space-between !important; */
  border-left: 1px solid var(--marketplaceColorBorder);
  & > *:not(:last-child) {
    /* margin-right: 8px; */
    /* margin-bottom: 8px; */
    height: auto;
  }
  
  @media (min-width: 992px) and (max-width: 1390px) {
    overflow-x: auto;
    padding: 13px 0 8px;
  }
  @media (max-width: 991px) {
    padding: 13px 0 8px;
    overflow: auto;
  }
 & > div{
  & > div{
    & > div{
      @media(max-width:991px){
        left:260px !important;
      }
    }
  }
 }
 & > div:last-child{
  & > div{
    & > div{
      left: auto !important;
    }
  }
 }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 0px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    padding: 0px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
