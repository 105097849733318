.title {
  width: 545px;
  height: 45px;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  color: #292929;
}

.seperatorSection {
  display: flex;
  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #a4a3a9;

  opacity: 0.9;
}

.seperatorText {
  min-width: fit-content;
  margin-right: 10px;
}

.seperator {
  margin: auto;
  width: 100%;
}

.halfInp {
  display: flex;
  justify-content: space-between;
  @media(max-width:767px){
    flex-direction:column;
  }
}

.facilityinfofields,
.facilityFormatRoot,
.facilitySurfaceRoot {
  flex-basis: 49%;
}

.sbmitcnclbtn {
  display: flex;
  margin: 60px 0;
  @media(max-width:575px){
    margin: 40px 0 0;
  }
  & button {
    flex-basis: 25%;
    margin-right: 30px;
    border-radius: 20px;
    @media(max-width:767px){
      flex-basis:100%;
      margin-right:0;

    }
  }
}

.cnclbtn {
  display: flex;
  align-items: center;
  color: #939291;
  &:hover,
  &:active,
  &:focus {
    color: #4a4a4a;
    cursor: pointer;
  }
}

.sbmtbtn {
  &:disabled {
    background-color: #b1b1b1;
  }
}
