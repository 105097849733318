.rootContainer {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.collapsable {
  height: auto;
  /* max-height: 0; */
  /* overflow: hidden; */
  transition: max-height 0.3s ease-in-out;
}

.collapsable > * {
  margin-top: 10px;
  @media (max-width: 1199px) {
    margin-top: 0;
  }
}

.collapsable > *:first-child {
  margin-top: 0;
}

.timeContainer,
.ParticipantsContainer,
.extraContainer,
.reservationContainer,
.facilitySizeContainer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  @media (max-width: 767px) {
    padding: 15px;
  }
}

.sessionContainer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.calendarContiner {
  cursor: pointer;
  display: inline;
  justify-content: space-between;
  /* padding: 10px; */
  & > div {
    padding: 0 10px;
    @media (max-width: 991px) {
      padding: 0 0px 0 10px;
    }
    & > div {
      & > div {
        & > div {
          & > div {
            & > div {
              & > div {
                & > div {
                  left: -55px !important;
                  @media (max-width: 767px) {
                    left: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px;
  }
}

.option {
  /* padding: 0 !important; */
  padding: 0 25px !important;
}

.timeContainer:hover,
.ParticipantsContainer:hover,
.extraContainer:hover,
.reservationContainer:hover,
.facilitySizeContainer:hover,
.calendarContiner:hover {
  /* background-color: #f5f5f5; */
}

.valueContainer {
  display: flex;
  flex-direction: column;
  & > div:nth-child(2) {
    & > div {
      & > div {
        & > div {
          & > div {
            & > div:nth-child(2) {
              @media (max-width: 1200px) {
                left: -65px !important;
              }
              @media (max-width: 991px) {
                left: 0 !important;
                top: 40px !important;
                border-top: 0 !important;
                min-height: auto !important;
              }
              @media (max-width: 767px) {
                left: 0 !important;
                min-height: auto !important;
                border-top: 0 !important;
              }
              & > div {
                & > div {
                  & > div {
                    @media (max-width: 767px) {
                      width: 100% !important;
                    }
                    & > div {
                      width: 300px;
                      /* height: 300px !important; */
                      background: #000 !important;
                      margin-left: 0 !important;
                      @media (max-width: 767px) {
                        width: 100% !important;
                      }
                      & > div:nth-child(2) {
                        left: -10px !important;
                        @media (max-width: 767px) {
                          left: 0px !important;
                          padding: 0 0 20px 0;
                        }
                      }
                      & > div {
                        @media (max-width: 767px) {
                          width: 100% !important;
                        }
                        & ul {
                          & li {
                            @media (max-width: 767px) {
                              width: 164px;
                            }
                          }
                        }
                        & > div {
                          /* left:0 !important; */
                          /* left: 139px !important; */
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  & > div {
    & > div {
      & > div {
        & > div:nth-child(2) {
          @media (max-width: 575px) {
            border-top: 0 !important;
          }
        }
      }
    }
  }
}
.noFacility {
  & > div:nth-child(2) {
    & > div {
      & > div {
        & > div {
          & > div {
            & > div:nth-child(2) {
              @media (min-width: 1200px) {
                left: 65px !important;
              }
              @media (min-width: 1024px) and (max-width: 1199px) {
                left: 47px !important;
              }
            }
          }
        }
      }
    }
  }
}
.label {
  font-size: 14px;
  font-weight: bold;
  /* margin-bottom: 5px; */
  text-transform: uppercase;
}

.velue {
  font-size: 16px;
  color: #666;
}

.arrowContainer {
  /* display: flex; */
  /* align-items: center; */
  position: absolute;
  right: 20px;
  & svg {
    height: 10px;
    width: 10px;
  }
  @media (max-width: 1200px) {
    right: 45px;
  }
  @media (max-width: 1023px) {
    right: 70px;
  }
  @media (max-width: 991px) {
    right: 50px;
  }
}

/* options selected section starts from here */

/* container styles */
.options-container {
  position: relative;
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

/* selected option styles */
.selected-option {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.selected-option:before {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* options list styles */
.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.option:hover {
  background-color: #ccc;
}

.selected {
  background-color: #ccc;
}

.selectContainer {
  /* overflow-y: scroll; */
  position: absolute;
  background: #fff;
  border: #f5f5f5 1px solid;
  border-radius: 30px;
  box-shadow: #666;
  /* max-height: 250px; */
  width: 100%;
  z-index: 1;

  overflow: hidden;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  @media (max-width: 1199px) {
    width: 90%;
  }
}

.optionsList {
  max-height: 250px;
  overflow: auto;
  padding: 20px 0;
}

.rangeRow,
.rangeDiv {
  display: flex;
  align-items: center;
}
.error {
  border: 1px solid #fc2c10 !important;
}
.rangeDiv {
  border: 1px solid #000000;
  background-color: #ffffff;
  padding: 9px 23px;
  border-radius: 40px;
  margin: 10px;
  & button {
    border: none;
  }
  & input {
    padding: 0;
    border: none;
    text-align: center;
  }
}

.msgContainer {
  text-align: center;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
  margin-left: 12px;
}

.rangeContainer {
  max-width: 200px;
  text-align: center;
}

.optionActive {
  background-color: #fe5f50;
  color: #fff;
}

.invalidInp {
  border-color: #fc2c10;
}

.halfInpGroup {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5db;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.halfInp {
  /* flex-basis: 45%; */
  flex-basis: 85%;
  position: relative;

  @media (min-width: 1400px) {
    flex-basis: 75%;
  }
  @media (min-width: 1600px) {
    flex-basis: 55%;
  }
  @media (min-width: 2000px) {
    flex-basis: 50%;
  }
  @media (max-width: 1200px) {
    flex-basis: 60%;
  }
  @media (max-width: 1199px) {
    flex-basis: 95%;
  }
  @media (max-width: 1024px) {
    flex-basis: 90%;
  }
  @media (max-width: 1023px) {
    flex-basis: 74%;
  }
  @media (max-width: 991px) {
    flex-basis: 70%;
  }
  & > div:nth-child(2) {
    right: 30px;
    @media (max-width: 1199px) {
      right: 15px;
    }
    @media (max-width: 991px) {
      right: 25px;
    }
    @media (max-width: 767px) {
      right: 30px;
    }
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #d1d5db;
  }
}

.arrowContainer {
  & svg {
    fill: none;
  }
}
.timeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div:nth-child(2) {
    & select {
      background-position: right 18px center;
      background-size: 12px;

      @media (min-width: 1200px) {
        background-position: right 12px center;
      }
      @media (min-width: 1400px) {
        background-position: right 12px center;
      }
      /* @media (min-width: 1600px) {
        background-position: right 45px center;
      } */
      /* @media (min-width: 2000px) {
        background-position: right 25px center;
      } */
      @media (max-width: 1023px) {
        background-size: 12px;
        background-position: right 12px center;
      }
      @media (max-width: 991px) {
        /* background-position: right 66px center; */
        background-position: right 12px center;
      }
      @media (max-width: 767px) {
        /* background-position: right 18px center; */
        background-position: right 18px center;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.select,
.selectDisabled {
  padding: 0;
  border: none !important;
  background-size: 10px !important;
  &:hover,
  &:focus {
    border: none !important;
  }
  @media (max-width: 767px) {
    background-position: right 18px center;
  }
}
.facilityWrap {
  align-items: baseline;
  column-gap: 10px;
  display: flex;
  border-bottom: 1px solid #d1d5db;
  & .selectContainer {
    top: 143px;
    @media (max-width: 1199px) {
      top: 230px;
    }
    @media (max-width: 992px) {
      top: 262px;
    }
    @media (max-width: 991px) {
      background-size: 12px !important;
      background-position: right 40px center !important;
      top: 230px;
    }
    @media (max-width: 767px) {
      top: 270px;
      width: 94%;
      right: 0;
      left: 16px;
    }
  }
  @media (max-width: 991px) {
    column-gap: 4px;
  }
  @media (max-width: 767px) {
    column-gap: 0;
    flex-direction: column;
  }
}
.valueContainerTwo {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    left: -8px;
    width: 1px;
    top: 5px;
    bottom: 5px;
    background-color: #d1d5db;
    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 1200px) {
      left: -12px;
    }
  }
  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            width: auto !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
}

.timeContainertwo {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    left: -5px;
    width: 1px;
    top: 5px;
    bottom: 3px;
    background-color: #d1d5db;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.ParticipantsContainer,
.extraContainer {
  border-bottom: 1px solid #d1d5db;
}
.reservationContainer {
  & > div:nth-child(3) {
    bottom: -9px;
  }
}
.hidefacility {
  display: none;
}
.hidePayment {
  display: none;
}
.hideBorder .valueContainerTwo {
  border-bottom: 0;
  &::after {
    display: none !important;
  }
}

.hideBorderBottom {
  border-bottom: none;
}
